import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import Image from '../../Image/Image'
import Icon from '../../Icon/Icon'
import Typography from '../../Typography/Typography'
import LoginStore from '../../UserManagement/Login-store'
import Button from '../../Button'
import tileStyles from './Tile.styles'
import ReceiptScanStore from '../../../containers/ReceiptScan/ReceiptScan.store'
import { comingsoon, completed, loginContextText } from '../../../constants/texts'
import { aboutCarousel } from '../../../constants/page-source'
import twStyles from './Tile.tw-styles'
import {
  isSurveyCompleted,
  isSegmantaLoaded,
  feedbackSurvey,
  getMasterSurveyID,
} from '../../../helpers/segmanta.helper'
import getSurveyId from '../../../containers/SurveyLanding/Survey.helper'
import getColor from '../../Icon/icon.helper'
import AimiaStore from '../../../containers/AIMIA/aimia.store'
import { carousalButtonClickEvent, triggerCompleteProfileClickEvent } from '../../../helpers/gaEvent.helper'
import { storageAvailable } from '../../../adapters/serverside.adapters'

const Tile = ({
  image,
  heading,
  text,
  link,
  activeStatus,
  behavior,
  iconName,
  textIconName,
  variant,
  pageSource,
  clickHandler,
  surveyName,
  completeStatusForRecieptScan,
  featuredSurvey,
  monthlyPromoRewardId,
  contextualLoginText,
  arrowColorVariant,
  takeFeedbackSurveyToGetPoints,
  cardPointsLabelInDashboardPage,
  subText,
  mosStartAndEndDate,
}) => {
  const [surveyCompleted, setSurveyCompleted] = useState(false)
  const pageStyle = twStyles[pageSource] || twStyles.default
  const style = pageStyle[variant] || pageStyle.default
  let handler = clickHandler
  const tileLink = link
  let surveyID = ''
  const tileCarousalButtonClick = () => carousalButtonClickEvent(
    // eslint-disable-next-line no-unsafe-optional-chaining
    window?.location.origin + (link || `/${behavior}`),
  )
  const pageSourceArr = ['Home', 'causeDetail']
  const behaviorArr = [null, 'Donate_Points']
  const getID = async () => {
    surveyID = await getMasterSurveyID(featuredSurvey, monthlyPromoRewardId, mosStartAndEndDate)
  }
  if (surveyName && surveyName.surveyName) {
    if (surveyName.surveyName === 'DEQ_MASTER' && LoginStore.rewardStatus) getID()
    else surveyID = getSurveyId(surveyName.surveyName)
  }
  const completeProfileHandler = () => {
    if (!LoginStore.rewardStatus) {
      LoginStore.context = contextualLoginText
      LoginStore.showSignInPopup = true
      if (typeof sessionStorage !== 'undefined') sessionStorage.setItem('showCompleteProfile', 'true')
    } else {
      LoginStore.showCompleteProfileOverlay = true
      LoginStore.completeProfileCTALaunched = true
    }
  }
  const fetchMemberHistoryAPI = async () => {
    AimiaStore.fetchMemberHistoryAction()
    const monthlyFeaturedPromosurveyAvalied = AimiaStore.redeemRewards
    && AimiaStore.redeemRewards.filter(
      (item) => item.rewardTypeExternalReference === monthlyPromoRewardId,
    )
    if (monthlyFeaturedPromosurveyAvalied
      && monthlyFeaturedPromosurveyAvalied.length > 0) setSurveyCompleted(true)
  }

  const handleTileButtonClickHandler = () => {
    if (pageSourceArr.includes(pageSource) && behaviorArr.includes(behavior)) {
      tileCarousalButtonClick()
    }
  }

  useEffect(() => {
    if (LoginStore.rewardStatus === true && behavior === 'Provide_Feedback_Survey') {
      isSegmantaLoaded(async () => {
        window.SEG_EMBED_API.on('SURVEY_GOAL_REACHED', async (scenarioid) => {
          if (scenarioid && scenarioid.toString() === process.env.DEQ_FEEDBACK_DEC_THINK_TANK) {
            setSurveyCompleted(true)
          }
        })
      })
    }
    if (LoginStore.rewardStatus && behavior === 'Provide_Feedback_Survey') {
      isSegmantaLoaded(async () => {
        const status = await isSurveyCompleted(process.env.DEQ_FEEDBACK_DEC_THINK_TANK)
        if (status) {
          setSurveyCompleted(true)
        }
      })
    }
  }, [LoginStore.rewardStatus])
  useEffect(() => {
    if (behavior === 'Take_Survey_Featured' && featuredSurvey) {
      fetchMemberHistoryAPI()
      if (LoginStore.userLoggedIn === true) {
        isSegmantaLoaded(async () => {
          window.SEG_EMBED_API.on('SURVEY_GOAL_REACHED', async (scenarioid) => {
            if (scenarioid.toString() === process.env.DEQ_MASTER_PROMO) {
              setSurveyCompleted(true)
            }
          })
        })
      }
    }
    if (
      pageSource === 'earnPoints'
      && surveyName
      && surveyName.surveyName
      && surveyName.surveyName !== 'DEQ_MASTER'
    ) {
      if (surveyID) {
        isSegmantaLoaded(async () => {
          const status = await isSurveyCompleted(surveyID)
          setSurveyCompleted(status)
        })
      }
    }
  }, [AimiaStore.earnRewards])
  switch (behavior) {
    case 'Complete_Profile':
      handler = () => completeProfileHandler()
      break
    case 'Receipt_Scan':
      handler = () => {
        ReceiptScanStore.setWidgetVisibility(
          true,
        )
        tileCarousalButtonClick()
      }
      break
    case 'Provide_Feedback_Survey':
      handler = () => {
        if (takeFeedbackSurveyToGetPoints) {
          isSegmantaLoaded(async () => {
            feedbackSurvey(process.env.DEQ_FEEDBACK_DEC_THINK_TANK, null, true)
          })
        }
      }
      break
    default:
      break
  }
  return (
    <>
      <Button
        disabled={
          surveyCompleted
          || completeStatusForRecieptScan
          || activeStatus
          || (LoginStore.surveyCompletedBadge
            && LoginStore.surveyCompletedBadge.indexOf(parseInt(surveyID, 10)) >= 0)
        }
        to={
          activeStatus
          || completeStatusForRecieptScan
          || surveyCompleted
          || (LoginStore.surveyCompletedBadge
            && LoginStore.surveyCompletedBadge.indexOf(parseInt(surveyID, 10)) >= 0)
            ? null
            : tileLink
        }
        handler={() => {
          if (!storageAvailable('localStorage')) {
            LoginStore.showCookiesDisabledodal = true
            return
          }
          if (behavior === 'Take_Survey' || behavior === 'Take_Survey_Featured') {
            feedbackSurvey(surveyID)
            if (pageSource === 'causeDetail') tileCarousalButtonClick()
          } else if (behavior === 'completeProfileSurvey') {
            if (LoginStore.rewardStatus) {
              triggerCompleteProfileClickEvent()
              feedbackSurvey(surveyID)
            } else {
              LoginStore.showSignInPopup = true
              LoginStore.context = loginContextText.featuredPromo
              LoginStore.pendingFor = 'completeProfileSurvey'
              localStorage.setItem('pendingfor', LoginStore.pendingFor)
            }
          } else if (behavior === 'buildYourProfileSurvey') {
            feedbackSurvey(process.env.DEQ_BUILD_YOUR_PROFILE)
          } else if (behavior === 'Carousel') {
            tileCarousalButtonClick()
          } else {
            handler()
            handleTileButtonClickHandler()
          }
        }}
        className={`${activeStatus ? style.dashboardWrapper : style.wrapper} 
        ${!LoginStore.completeProfile20SurveyTaken ? '' : 'complete-tile'}`}
        aria-label={`${(image?.altText || text)}`}
      >
        {(surveyCompleted
          || completeStatusForRecieptScan
          || (LoginStore.surveyCompletedBadge
            && LoginStore.surveyCompletedBadge.indexOf(parseInt(surveyID, 10))
              >= 0)) && (
              <div className={style.completedWrapper}>
                <div className={style.completedBtn}>{completed}</div>
                <div className='letsGetStartedTile surveyletsGetStartedTile' />
              </div>
        )}
        {activeStatus ? (
          <div
            className={
              activeStatus ? style.dashboardImage : style.comingsoontile
            }
          >
            {comingsoon}
          </div>
        ) : (
          ''
        )}
        {activeStatus ? (
          <div
            className={
              activeStatus
                ? style.comingRewardsSoontileBg
                : style.comingsoontileBg
            }
          />
        ) : (
          ''
        )}
        {(image || iconName) && (
          <div className={style.image}>
            {image && (
              <Image
                className={style.iconImage}
                {...image}
                altText={image.altText || text}
              />
            )}
            {iconName && <Icon className={style.iconSvg} name={iconName} />}
            {(pageSource === 'rewardsDashboard' || pageSource === 'earnPoints') && cardPointsLabelInDashboardPage && (
            <>
              <div className={style.imageFlag}>
                <Typography
                  markdown={cardPointsLabelInDashboardPage}
                />
              </div>
            </>
            )}
          </div>
        )}
        {(subText || text) && (
          <div className={style.text}>
              {heading && (
              <Typography
                className={style.textHeader}
                markdown={heading}
              />
              )}
            <div
              className={
                variant === aboutCarousel ? style.text : style.textSecWrapper
              }
            >
              {textIconName || arrowColorVariant ? (
                <Typography
                  markdown={text}
                  renderers={{
                    link: ({ href }) => (
                      <>
                        {href === 'icon' && (
                        <Icon
                          className={style.iconColorVariant}
                          name={textIconName}
                          color={arrowColorVariant && getColor(arrowColorVariant)}
                        />
                        )}
                      </>
                    ),
                  }}
                />
              ) : (
                <>
                  {text && pageSource !== 'articlePledge' && (
                  <Typography
                    className={
                    variant === 'whatsNew-cause'
                      ? style.textHome
                      : style.text
                  }
                    markdown={text}
                  />
                  )}
                </>
              )}
              <Typography
                markdown={subText}
                className={style.linkText}
                renderers={{
                  link: ({ href }) => (
                    <>
                      {href === 'icon' && (
                        <Icon
                          className='w-10 inherit quizArrow'
                          name={textIconName}
                          color={arrowColorVariant && getColor(arrowColorVariant)}
                        />
                      )}
                    </>
                  ),
                }}
              />
            </div>
          </div>
        )}
        <style jsx global>
          {tileStyles}
        </style>
      </Button>
    </>
  )
}

Tile.propTypes = {
  image: PropTypes.instanceOf(Object),
  text: PropTypes.string,
  name: PropTypes.string,
  href: PropTypes.string,
  link: PropTypes.string,
  activeStatus: PropTypes.bool,
  receiptScanCompleted: PropTypes.bool,
  behavior: PropTypes.string,
  iconName: PropTypes.string,
  textIconName: PropTypes.string,
  heading: PropTypes.string,
  variant: PropTypes.string,
  pageSource: PropTypes.string,
  clickHandler: PropTypes.func,
  surveyName: PropTypes.string,
  completeStatusForRecieptScan: PropTypes.bool,
  featuredSurvey: PropTypes.bool,
  monthlyPromoRewardId: PropTypes.string,
  contextualLoginText: PropTypes.string,
  arrowColorVariant: PropTypes.string,
  takeFeedbackSurveyToGetPoints: PropTypes.string,
  cardPointsLabelInDashboardPage: PropTypes.string,
  subText: PropTypes.string,
  mosStartAndEndDate: PropTypes.instanceOf(Object),
}

Tile.defaultProps = {
  name: null,
  href: null,
  surveyName: null,
  image: null,
  text: null,
  link: null,
  activeStatus: false,
  receiptScanCompleted: false,
  behavior: null,
  iconName: null,
  textIconName: null,
  heading: null,
  variant: null,
  pageSource: null,
  clickHandler: () => {},
  completeStatusForRecieptScan: null,
  featuredSurvey: false,
  monthlyPromoRewardId: '',
  contextualLoginText: '',
  arrowColorVariant: null,
  takeFeedbackSurveyToGetPoints: false,
  cardPointsLabelInDashboardPage: null,
  subText: null,
  mosStartAndEndDate: null,
}

export default observer(Tile)
