import {
  home, signUp, waysToEarnPoints, rewardsDashboard, coupons, causeDetail, articleDetail,
  refferaFriend, earnPoints, sustainability, rebates,
} from '../../../constants/page-source'

module.exports = {
  default: {
    default: {},
    wrapper: 'default-tile-style-add-styles-in-contentful focus:outline-none pointer-events-none',
    image: 'default-tile-image-style-add-styles-in-contentful',
    text: 'default-tile-text-style-add-styles-in-contentful',
    iconColorVariant: 'w-10 inherit',
    completedWrapper: 'opacity relative survey-completed',
    completedBtn: 'tileDeqInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
  },
  [home]: {
    default: {
      wrapper: 'w-full flex md:block px-15 py-10',
      image: '2',
      text: 'px-10 homeIntro-Rewards font-montserratBold font-bold text-left text-14 leading-lightmedium',
      icon: '4',
      iconImage: '5',
      iconColorVariant: 'w-10 inherit',
      textSecWrapper: 'pt-05',
    },
    Magenta: {
      default: {},
      wrapper: 'getstarted-points-carousel-tile bg-white flex flex-wrap h-194 md:h-200 lg:h-285 justify-left mr-13px relative border-t-2 border-info',
      image: 'px-25 pt-30 items-baseline lg:pt-38 lg:pl-40 lg:pt-40 w-full pb-05 lg:pb-15 flex flex-wrap justify-left items-center getstarted-carousel-image',
      text: 'getstarted-points-text text-left text-14 pr-05 md:pr-25 lg:text-17 pb-25 pl-25 lg:pb-40 lg:pl-40 font-montserratSemiBold font-semibold leading-leading20 lg:leading-lightmedium',
      icon: 'getstarted-points-carousel-icon w-47 h-47 lg:w-66 lg:h-66',
      iconImage: 'getstarted-points-carousel-icon-image w-47 h-47 lg:w-66 lg:h-66',
      iconColorVariant: 'w-10 inherit',
    },
    Teal: {
      default: {},
      wrapper: 'getstarted-save-carousel-tile w-full bg-white flex flex-wrap h-194 md:h-200 lg:h-285 justify-left mr-13px relative border-t-2 border-secondary',
      image: 'px-25 pt-30 items-baseline lg:pt-38 lg:pl-40 lg:pt-40 w-full pb-05 lg:pb-15 flex flex-wrap justify-left items-center getstarted-carousel-image',
      text: 'getstarted-save-text text-left text-14 pr-30 md:pr-35 lg:pr-20 lg:text-17 pb-25 pl-25 lg:pb-40 lg:pl-40 font-montserratSemiBold font-semibold leading-leading20 lg:leading-lightmedium',
      icon: 'getstarted-save-carousel-icon w-47 h-47 lg:w-66 lg:h-66',
      iconImage: 'getstarted-save-carousel-icon-image w-47 h-47 lg:w-66 lg:h-66',
      iconColorVariant: 'w-10 inherit',
    },
    Red: {
      default: {},
      wrapper: 'getstarted-good-carousel-tile bg-white flex flex-wrap h-194 md:h-200 lg:h-285 justify-left mr-13px relative border-t-2 border-redRewards focus:outline:non',
      image: 'px-25 items-baseline md:pt-30 pt-30 lg:pl-40 lg:pt-40 w-full pb-05 lg:pb-15 flex flex-wrap justify-left items-center getstarted-carousel-image',
      text: 'getstarted-good-text text-left text-14 pr-10 md:pr-40 lg:pr-25 lg:text-17 pb-25 pl-25 lg:pb-40 lg:pl-40 font-montserratSemiBold font-semibold leading-leading20 lg:leading-lightmedium',
      icon: 'getstarted-good-carousel-icon w-47 h-47 lg:w-66 lg:h-66',
      iconImage: 'getstarted-good-carousel-icon-image w-47 h-47 lg:w-66 lg:h-66',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnScanReceipt: {
      wrapper: 'scanReceipt w-full lg:flex-col eachCard articalmd pb-15 md:pb-15 lg:pb-0 mx-auto flex flex-wrap md:flex-row w-full md:w-6/12 lg:w-4/12 cardItem px-10',
      image: 'w-full px-0 min-h-140 md:min-h-155 lg:min-h-170 justify-center items-center flex scanReceipt-bg',
      text: 'cardText px-30 bg-white pt-20 pb-40 md:min-h-100 lg:min-h-135 font-montserratBold font-bold leading-seller18 tracking-1 text-8 lg:text-14 lg:tracking-onehalf',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
    },
    'Article Carousel': {
      default: {},
      wrapper: 'home-card-tile home-card-carousel-old w-140 md:w-auto items-center justify-center bg-white block mr-0 leading-tight overflow-hidden min-h-full min-h-40',
      image: 'md:px-0 w-full min-h-140 home-card-image',
      text: 'home-card-carousel-tile-old flex flex-grow self-stretch items-center px-1 md:px-05 xl:px-11 min-h-70 lg:min-h-100 text-center justify-start text-14 pt-05 pb-05 md:pt-11 md:pb-11 lg:pb-05 lg:pt-10 font-montserratSemiBold font-semibold leading-points16 md:leading-leading15 lg:leading-seller18 text-sign-head',
      icon: 'Article-carousel-icon',
      iconColorVariant: 'w-10 inherit',
    },
    'Brand Carousel': {
      default: {},
      wrapper:
        'home-card-tile w-80 lg:w-140 cursor-auto lg:w-11/12 items-center justify-center md:mx-auto block m-1 md:mb-10px lg:mb-13px bg-white shadow-articleCarousel overflow-hidden min-h-full',
      image: ' p-10 lg:p-5 min-h-80 xxl:min-h-185',
      text:
        'home-card-tile-text px-2 md:p-2 lg:p-6 md:pl-4 hidden text-center  justify-start text-14 lg:text-lg py-2 font-montserratlight font-light text-sign-head',
      icon: 'brand-carousel-icon',
      iconColorVariant: 'w-10 inherit',
    },
    'About Home Tile': {
      default: {},
      wrapper: 'wrapperAboutHome bg-white flex md:block xl:flex items-center h-full px-10 pb-20 pt-10 md:px-0 xxl:pr-10 md:pl-20 md:pr-0 lg:pr-15 lg:pl-30 xxl:pl-25 xl:pl-20 xl:pr-0 md:pt-20 xl:pt-30 xl:pb-30 xl:items-start',
      image: 'wrapperAboutHomeImage block min-w-100 md:min-w-158',
      iconImage: 'iconImageAboutHome min-w-100 w-95 md:min-w-158 md:w-150 xl:w-195',
      textHeader: 'wrapperAboutHomeHeader font-montserratBold font-bold md:pt-10 text-sm leading-lightmedium xl:text-lg xl:pt-25 pl-08',
      text: 'wrapperAboutHomeText text-left font-montserratSemiBold font-semibold text-14 xl:text-14 leading-seller18 xl:leading-leading20  w-11/12 md:w-full md:min-h-70 xl:min-h-auto pl-08',
      iconColorVariant: 'w-10 inherit',
    },
    'whatsNew-cause': {
      default: {},
      wrapper: 'w-full bg-white flex relative border-t-2 border-secondary mb-10px xl:mb-17px',
      image: 'w-full',
      text: 'whatsNew-text text-secondary text-left text-14 pr-30 md:pr-35 lg:pr-20 lg:text-17 py-25 pl-25 lg:py-40 lg:pl-40 font-montserratSemiBold font-semibold leading-leading20 lg:leading-lightmedium',
      textHome: 'text-sign-head',
      icon: 'w-full',
      iconImage: '',
      iconColorVariant: 'w-10 inherit',
    },
    'feature-promotions': {
      default: {},
      wrapper: 'bg-white block',
      image: 'wrapperAboutHomeImage flex justify-center',
      iconImage: 'iconImageAboutHome w-full',
      textHeader: 'font-montserratBold font-bold text-primary pb-04 md:pb-08 text-14 tracking-1 lg:text-14 lg:tracking-onehalf lg:leading-seller18',
      text: 'homeWaytoEarnCard-text border-t-2 border-primary text-left font-montserratSemiBold font-semibold text-14 leading-leading20 xl:leading-lightmedium w-full min-h-135 md:min-h-140 lg:min-h-144 xl:min-h-155 pl-25 pb-25 pr-22px pt-18px lg:pt-25 md:pr-55 lg:pr-40 xxl:pr-45 pb-30 lg:',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileSurveyInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
  },
  [earnPoints]: {
    default: {
      wrapper: 'items-center justify-center pb-2 lg:px-2 w-4/12 pointer-events-none ',
      image: 'signup-card-tile-image mx-auto px-0 justify-center',
      text:
        'justify-center text-14 pl-0 pr-05 lg:px-0 xl:px-0 pt-15  md:px-05 flex flex-grow lg:pt-0 leading-tight',
      icon: 'default-icon',
      iconColorVariant: 'w-10 inherit',
    },
    'earnPoints-feature-promotions': {
      default: {},
      wrapper: 'featureF bg-white shadowarticleCarousel w-271 block lg:w-260 xl:w-283 xxl:w-330',
      image: 'wrapperAboutHomeImage flex justify-center',
      iconImage: 'iconImageAboutHome w-full',
      textHeader: 'font-montserratBold font-bold text-primary pb-04 md:pb-08 text-14 tracking-1 lg:text-14 lg:tracking-onehalf lg:leading-lightmedium',
      text: 'homeWaytoEarnCard-text border-t-2 border-primary text-left font-montserratSemiBold font-semibold text-14 leading-leading20 xl:leading-lightmedium w-full min-h-135 md:min-h-140 xl:min-h-auto pl-25 pb-25 pr-22px pt-18px lg:pt-25 md:pr-55 lg:pr-38 xxl:pr-45 pb-30 lg:',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileSurveyInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
    'earnPoints-feature-promotionsDeq': {
      default: {},
      wrapper: 'featureF bg-white shadowarticleCarousel w-271 block lg:w-260 xl:w-283 xxl:w-330',
      image: 'wrapperAboutHomeImage flex justify-center',
      iconImage: 'iconImageAboutHome w-full',
      textHeader: 'font-montserratBold font-bold text-blue pb-04 md:pb-08 text-14 tracking-1 lg:text-14 lg:tracking-onehalf lg:leading-leading20',
      text: 'homeWaytoEarnCard-text border-t-2 border-blue text-left font-montserratSemiBold font-semibold text-14 leading-leading20 w-full min-h-135 md:min-h-140 xl:min-h-auto pl-25 pb-25 pr-22px pt-18px lg:pt-25 md:pr-35 lg:pr-40 xxl:pr-45 pb-30 lg:',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileQuizEarnInactive text-white text-14 leading-leading20 lg:leading-leading20 lg:tracking-1Point56 flex justify-center items-center font-montserratBold font-bold lg:text-14 tracking-onethree',
    },
    'feature-earn-points': {
      default: {},
      wrapper: 'quizS bg-white w-271 relative pb-15 font-montserratSemiBold font-semibold text-14 xl:text-14 leading-leading20 block xl:w-283 xxl:w-330',
      image: 'wrapperAboutHomeImage flex justify-center relative border-b-0 border-primary',
      iconImage: 'iconImageAboutHome w-full',
      imageFlag: 'imageFlag-text absolute top-5 left-5 xl:top-10 xl:left-10 text-center rounded-full w-70 h-70 flex items-center justify-center flex-wrap text-32 pt-02 xl:leading-points16 bg-primary font-bold text-white',
      textHeader: 'feature-earnPoints pl-20 pr-18 font-semibold pt-20 text-primary bg-white pb-10 text-14 md:pb-08 lg:text-18 lg:leading-small lg:pl-30 lg:pr-30 lg:pb-15 ',
      text: 'text-left  w-full min-h-80 xl:min-h-100 ',
      iconColorVariant: 'w-10 inherit',
      linkText: 'underline absolute bottom-10 w-10/12 text-14 xl:text-16 leading-lightmedium',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileDeqInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
  },
  [rebates]: {
    default: {
      wrapper: 'bg-white shadowarticleCarousel w-271 block md:w-280 lg:w-260 xl:w-283 xxl:w-330',
      image: 'wrapperAboutHomeImage flex justify-center',
      iconImage: 'iconImageAboutHome w-full',
      textHeader: 'font-montserratBold uppercase font-bold text-blue pb-04 md:pb-08 text-14 tracking-1 lg:text-14 lg:tracking-onehalf lg:leading-leading20',
      text: 'homeWaytoEarnCard-text border-t-2 border-blue text-left font-montserratSemiBold font-semibold text-14 leading-leading20 w-full min-h-135 md:min-h-140 xl:min-h-auto pl-25 pb-25 pr-22px pt-18px lg:pt-25 md:pr-35 lg:pr-40 xxl:pr-45 pb-30 lg:',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileQuizEarnInactive text-white text-14 leading-leading20 lg:leading-leading20 lg:tracking-1Point56 flex justify-center items-center font-montserratBold font-bold lg:text-14 tracking-onethree',
    },
    'rebates-feature-promotions': {
      default: {},
      wrapper: 'featureF bg-white shadowarticleCarousel w-271 block lg:w-260 xl:w-283 xxl:w-330',
      image: 'wrapperAboutHomeImage flex justify-center',
      iconImage: 'iconImageAboutHome w-full',
      textHeader: 'font-montserratBold font-bold uppercase text-blue pb-04 md:pb-08 text-14 tracking-1 lg:text-14 lg:tracking-onehalf lg:leading-leading20',
      text: 'homeWaytoEarnCard-text border-t-2 border-blue text-left font-montserratSemiBold font-semibold text-14 leading-leading20 w-full min-h-135 md:min-h-140 xl:min-h-auto pl-25 pb-25 pr-22px pt-18px lg:pt-25 md:pr-35 lg:pr-40 xxl:pr-45 pb-30 lg:',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileQuizEarnInactive text-white text-14 leading-leading20 lg:leading-leading20 lg:tracking-1Point56 flex justify-center items-center font-montserratBold font-bold lg:text-14 tracking-onethree',
    },
  },
  [signUp]: {
    default: {
      wrapper:
        'items-center justify-center pb-2 lg:px-2 w-4/12 pointer-events-none ',
      image: 'signup-card-tile-image mx-auto px-0 justify-center',
      text:
        'justify-center text-14 pl-0 pr-05 lg:px-0 xl:px-0 pt-15  md:px-05 flex flex-grow lg:pt-0 leading-tight',
      icon: 'default-icon',
      iconColorVariant: 'w-10 inherit',
    },
  },
  [waysToEarnPoints]: {
    default: {
      wrapper: 'way-to-earn-tile-wrapper w-full flex pt-20 lg:pt-35 lg:mr-30px w-10/12 cursor-default md:w-5/12 lg:w-2/12 md:block mx-auto justify-end lg:ml-0 md:mr-15px md:ml-0 mx-10px mb-10px focus:outline-none',
      image: '  flex flex-wrap items-center md:justify-center lg:mb-20px mb-13px',
      text: 'way-to-learn-text text-left md:text-center text-base lg:text-lg pl-10 md:px-10 lg:px-05',
      iconColorVariant: 'w-10 inherit',
    },
    inactive: {
      wrapper: 'way-to-earn-tile-wrapper w-full flex pt-20 lg:pt-35 w-10/12 md:w-5/12 lg:w-1/5 md:block mx-auto  cursor-default lg:mr-30px lg:ml-0 md:mr-25px md:ml-0 mx-15px mb-10px focus:outline-none',
      image: ' flex flex-wrap items-center md:justify-center lg:mb-20px mb-13px',
      text: 'way-to-learn-text text-left md:text-center  text-base lg:text-lg pl-10 md:px-10  lg:px-05',
      iconColorVariant: 'w-10 inherit',
    },
  },
  [refferaFriend]: {
    default: {
      wrapper: ' w-full flex pb-30 xl:pb-40 cursor-default md:min-h-125 xl:min-h-155 xxl:min-h-125 focus:outline-none',
      image: '',
      text: 'pl-12 xl:pl-18 reffer-earn-text text-left font-montserratlight font-light text-16 xl:text-18 leading-lightmedium xl:leading-widest',
      iconColorVariant: 'w-10 inherit',
    },
  },
  [rewardsDashboard]: {
    default: {
      wrapper:
        'rewards-dashboard-tile bg-white shadow-rewardsTile items-center w-full lg:w-330 md:w-280 md:mx-8px lg:mx-15px lg:my-11px justify-center my-10px md:my-8px block relative',
      dashboardWrapper: 'dashboard-wrapper cursor-not-allowed relative shadow-rewardsTile items-center lg:w-330 md:w-280 w-full md:mx-10px lg:mx-15px lg:my-10px justify-center my-2 block focus:outline-none ',
      image: 'rewards-dashboard-tile-image px-0 min-h-130 lg:min-h-170 relative',
      imageFlag: 'absolute top-20 left-0 uppercase text-14 font-bold tracking-onehalf leading-seller18 py-08 pl-15 pr-12 text-white bg-warning',
      iconImage: 'w-full',
      dashboardImage: 'comming-soon-badge flex flex-wrap items-center justify-center shadow-rewardsBadge uppercase font-montserratBold font-bold text-14 text-white',
      comingRewardsSoontileBg: 'rewards-de-active',
      text: 'rewards-dashboard-tile-text bg-white text-left pl-25 pr-27 md:pr-31 pt-10 pb-25 xl:pl-35 xl:pr-37 xl:pt-15 xl:pb-32 justify-start text-14 font-montserratSemiBold font-semibold leading-leading20',
      textHeader: 'text-warning font-semiBold text-16 xl:text-18 leading-widest',
      textSecWrapper: 'font-semiBold text-secWrapper text-16 xl:text-18 leading-light xl:leading-lightmedium',
      iconColorVariant: 'w-10 hidden',
    },
    sweepsstakes: {
      wrapper: 'sweep-rewards-tile shadow-articalmd bg-white md:flex md:ml-13px mb-10px md:mr-0px md:w-11/12 lg:w-full lg:mr-25px md:border-t-1 lg:border-t-2 md:border-redRewards',
      image: 'sweep-rewards-img border-t-onehalf md:border-t-0 border-redRewards md:flex-shrink-0 md:w-4/12 lg:w-5/12',
      text: 'sweep-rewards-text  text-left pt-20 pl-30 pr-20 pb-25 lg:pl-40 lg:pt-50 ',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnTakeSurvey: {
      wrapper: 'takeSurvey w-271 block md:w-full relative z-5 cardItem md:bg-white lg:bg-transparent md:shadow-articalmd lg:shadow-none eachCard pb-15 mx-auto flex flex-wrap md:flex-row md:items-center md:pb-0 lg:pb-0 lg:flex-col',
      iconSvg: 'iconImg w-71 lg:w-90',
      image: 'w-full px-0 min-h-140 shadowarticalmd justify-center items-center takeSurveybg flex md:min-h-150 md:-271 md:w-full lg:min-h-130 lg:w-full xxl:min-h-170',
      text: 'cardText w-full text-left shadow-articalmd md:shadow-none md:w-6/12 lg:w-full pl-25 pr-22 md:min-h-122 lg:min-h-115 bg-white lg:bg-white md:bg-transparent pt-20 pb-20 font-montserratBold font-bold md:pt-40 md:pl-40 md:pr-30 md:pb-20 leading-seller18 tracking-1 text-14 lg:text-14 lg:tracking-onehalf lg:pb-20 lg:pt-20 lg:mr-1px lg:mb-2px',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnScanReceipt: {
      wrapper: 'scanReceipt w-271 md:w-full md:h-full shadow-none md:shadow-articalmd lg:shadow-none md:bg-white cardItem eachCard flex flex-wrap pb-15 md:flex-row md:pb-0 md:flex-col md:items-start',
      iconSvg: 'iconImg w-71 lg:w-90',
      image: ' w-full shadowarticalmd px-0 md:-271 md:w-full min-h-140 justify-center items-center flex md:min-h-150 lg:min-h-130 xxl:min-h-170 lg:w-full',
      text: 'cardText w-full shadow-articalmd md:min-h-125 md:shadow-none font-montserratBold font-bold leading-seller18 tracking-1 text-14 pl-25 pr-22 md:pr-32 bg-white pt-20 pb-20 md:min-h-125 md:-271 md:w-full md:pb-20 lg:min-h-115 lg:text-14 lg:tracking-onehalf lg:px-30 lg:w-full lg:pb-20',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnReferAFriend: {
      wrapper: 'referAFriend w-271 md:w-full md:h-full shadow-none md:shadow-articalmd lg:shadow-none md:bg-white lg:flex-col eachCard cardItem pb-15 mx-auto flex flex-wrap md:pb-0 md:flex-row lg:pb-0',
      iconSvg: 'iconImg w-71 lg:w-90',
      image: ' w-full shadowarticalmd px-0 md:-271 md:w-full min-h-140 justify-center items-center flex md:min-h-150 lg:min-h-130 xxl:min-h-170 lg:w-full ',
      text: 'cardText w-full shadow-articalmd md:shadow-none font-montserratBold font-bold leading-seller18 tracking-1 text-14 pl-25 pr-25 bg-white text-left pt-20 pb-20 md:271 md:w-full md:min-h-125 md:pb-20 lg:min-h-115 lg:text-14 lg:tracking-onehalf lg:px-30 lg:w-full lg:pb-20',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileDeqInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
  },
  [coupons]: {
    default: {
      wrapper: 'sweep-coupons-tile shadow-articalmd bg-white md:flex md:ml-0px md:mr-0px md:w-full md:border-t-1 lg:border-t-2 md:border-secondary lg:mr-25px',
      image: 'sweep-coupons-img mt-20px md:mt-0 border-t-onehalf lg:border-t-0 border-secondary md:flex-shrink-0 md:w-4/12 lg:w-auto',
      text: 'sweep-coupons-text bg-white text-left pt-20 pl-30 pr-20 pb-25 lg:pb-0 md:pt-40 lg:pr-30 md:pl-40 lg:pl-70 lg:pt-50 xl:pt-55 md:w-7/12 lg:w-10/12 ',
      icon: 'sweep-coupons-icon',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnTakeSurvey: {
      wrapper: 'takeSurvey w-285 block md:w-full relative z-5 cardItem md:bg-white lg:bg-transparent md:shadow-articalmd lg:shadow-none eachCard pb-15 mx-auto flex flex-wrap md:flex-row md:items-center md:pb-0 lg:pb-0 lg:flex-col',
      iconSvg: 'iconImg w-71 lg:w-90',
      image: 'w-full px-0 min-h-140 shadowarticalmd justify-center items-center takeSurveybg flex md:min-h-150 md:-271 md:w-full lg:min-h-130 lg:w-full xxl:min-h-170',
      text: 'cardText w-full text-left shadow-articalmd md:shadow-none md:w-6/12 lg:w-full pl-25 pr-22 md:min-h-122 lg:min-h-115 bg-white lg:bg-white md:bg-transparent pt-20 pb-20 font-bold md:pt-40 md:pl-40 md:pr-30 md:pb-20 leading-leading22 text-16 lg:pb-20 lg:pt-20 lg:mr-1px lg:mb-2px',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnScanReceipt: {
      wrapper: 'scanReceipt w-285 md:w-full md:h-full shadow-none md:shadow-articalmd lg:shadow-none md:bg-white cardItem eachCard flex flex-wrap pb-15 md:flex-row md:pb-0 md:flex-col md:items-start',
      iconSvg: 'iconImg w-71 lg:w-90',
      image: ' w-full shadowarticalmd px-0 md:-271 md:w-full min-h-140 justify-center items-center flex md:min-h-150 lg:min-h-130 xxl:min-h-170 lg:w-full',
      text: 'cardText w-full shadow-articalmd md:min-h-125 md:shadow-none font-montserratBold font-bold text-left leading-leading22 pl-25 pr-22 md:pr-32 bg-white pt-20 pb-20 md:min-h-125 md:-271 md:w-full md:pb-20 lg:min-h-115 text-16 lg:px-30 lg:w-full lg:pb-20',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
    },
    startEarnReferAFriend: {
      wrapper: 'referAFriend w-285 md:w-full md:h-full shadow-none md:shadow-articalmd lg:shadow-none md:bg-white lg:flex-col eachCard cardItem pb-15 mx-auto flex flex-wrap md:pb-0 md:flex-row lg:pb-0',
      iconSvg: 'iconImg w-71 lg:w-90',
      image: ' w-full shadowarticalmd px-0 md:-271 md:w-full min-h-140 justify-center items-center flex md:min-h-150 lg:min-h-130 xxl:min-h-170 lg:w-full ',
      text: 'cardText w-full shadow-articalmd md:shadow-none font-montserratBold font-bold leading-leading22 pl-25 pr-25 bg-white text-left pt-20 pb-20 md:271 md:w-full md:min-h-125 md:pb-20 lg:min-h-115 text-16 lg:px-30 lg:w-full lg:pb-20',
      icon: 'sweep-rewards-icon',
      iconColorVariant: 'w-10 inherit',
      completedWrapper: 'opacity relative survey-completed',
      completedBtn: 'tileDeqInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
    },
  },
  articlePledge: {
    default: {
      wrapper: ' w-300 h-113 shadow-articleCarousel flex items-center gradientPledge',
      newWrapper: 'pledge-taken',
      image: 'pl-30',
      iconSvg: 'w-72 h-72',
      text: 'pl-25 font-montserratSemiBold font-semibold text-16 leading-seller18 text-white pr-10 text-left',
      icon: '',
      iconColorVariant: 'w-10 inherit',
    },
  },
  [articleDetail]: {
    default: {},
    about: {
      default: {},
      wrapper: 'w-full lg:pb-0 pointer-events-non cursor-default focus:outline-none',
      image: 'lg:pt-40 pt-30 mx-auto w-full',
      textHeader: 'w-full lg:text-18 font-montserratSemiBold font-semibold pt-0 pb-10 pb-15 leading-small lg:leading-normal',
      text: 'Article ArticleCause w-full font-montserratlight font-light text-14 pr-0 pl-03 leading-leading20 text-left article-detail-listingUl',
      iconColorVariant: 'w-10 inherit',
    },
  },
  [causeDetail]: {
    default: {},
    about: {
      default: {},
      wrapper: 'w-full lg:pb-0 pointer-events-none lg:mt-40 mt-30 relative bg-bgcolor xl:min-h-300',
      image: 'mx-auto w-full',
      text: 'flex flex-grow justify-between text-left text-14 py-30  font-montserratlight font-light text-sign-head lg:leading-lightmedium pt-05 w-9/12 lg:w-10/12 xl:w-11/12',
      iconColorVariant: 'w-10 inherit',
    },
    explore: {
      default: {},
      wrapper: 'explore-cause-carousel-tile items-center justify-center bg-white block mr-3 ml-0 md:mr-2 md:ml-2 lg:mr-1 lg:ml-1 xl:ml-7px xl:mr-7px leading-tight shadow-articleCarousel mb-2px mt-2px overflow-hidden min-h-246 md:min-h-240 lg:min-h-324',
      image: 'explorer-Img flex flex-wrap justify-center lg:pt-40 pt-35 mx-auto  w-80 pl-06',
      text: 'flex flex-grow justify-center text-center text-14 lg:text-16 font-montserratSemiBold font-semibold text-sign-head lg:leading-lightmedium px-07 md:px-08 lg:px-12 leading-leading20 xxl:px-17 pb-10 pt-10 md:py-10 lg:pt-15 explore-cause-carousel-text',
      iconColorVariant: 'w-10 inherit',
    },
    'Article Carousel': {
      default: {},
      wrapper: 'home-card-tile home-card-carousel-old w-140 md:w-auto items-center justify-center bg-white block mr-0 leading-tight overflow-hidden min-h-full min-h-40',
      image: 'md:px-0 w-full relative min-h-140 home-card-image',
      text: 'home-card-carousel-tile-old flex flex-grow self-stretch items-center px-1 md:px-05 min-h-70 lg:min-h-100 text-center justify-start text-14 lg:text-base pt-05 pb-05 md:pt-11 md:pb-11 lg:pb-05 lg:pt-10 font-montserratSemiBold font-semibold leading-points16 lg:leading-leading20 text-sign-head',
      iconColorVariant: 'w-10 inherit',
    },
    howCanYouHelp: {
      default: {},
      wrapper: 'always-carouselCard flex-wrap inline-block xxl:w-11/12 justify-center mx-8px md:mr-13px md:ml-0 relative',
      image: 'px-33 md:px-36 pt-38 lg:px-30 lg:pt-40 w-full pb-20 flex flex-wrap justify-center items-center always-carousel-image',
      comingsoontile: 'coming soon comming-soon-badge-cause cursor-default flex flex-wrap items-center justify-center shadow-rewardsBadge uppercase font-montserratBold font-bold text-14 text-white',
      comingsoontileBg: 'cause-de-active',
      completedWrapper: 'opacity',
      completedBtn: 'tileDeqInactive text-white text-13 leading-navline flex justify-center items-center font-montserratBold font-bold lg:text-15 lg:leading-articalsmall',
      text: 'text-center text-white text-center impact-help-gradiant text-14 lg:text-18 pb-20 lg:pb-16 font-montserratSemiBold font-semibold px-10 leading-seller18 lg:leading-light',
      iconColorVariant: 'w-10 inherit',
    },
    TilesInContent: {
      default: {},
      wrapper: 'end-plastic-cleanup-tile cursor-default bg-white flex flex-wrap px-25 md:px-20 py-25 md:pb-10 xl:pb-40 justify-left  relative ',
      image: 'end-plastic-cleanup-img',
      text: 'end-plastic-cleanup-save-text md:inline lg:min-h-120 text-left pt-10 md:pt-20 md:pb-10 text-14 leading-seller18 lg:text-sm lg:leading-leading20  font-montserratSemiBold font-semibold text-success',
      icon: 'end-plastic-cleanup-carousel-icon',
      iconColorVariant: 'w-10 inherit',
    },
    TilesBlackInContent: {
      default: {},
      wrapper: 'arbor-tile bg-white flex flex-wrap px-25 md:px-20 py-25 md:pb-10 xl:pb-30 justify-left  relative ',
      image: 'end-plastic-cleanup-img',
      text: 'end-plastic-cleanup-save-text md:inline lg:min-h-120 text-left pt-10 md:pt-20 md:pb-10 text-14 leading-seller18 lg:text-sm lg:leading-leading20  font-montserratSemiBold font-semibold',
      icon: 'end-plastic-cleanup-carousel-icon',
      iconColorVariant: 'w-10 inherit',
    },
    TilesBlackInContentnoCursor: {
      default: {},
      wrapper: 'end-plastic-cleanup-tile bg-white cursor-default focus:outline-none flex flex-wrap px-25 md:px-20 py-25 md:pb-10 xl:pb-30 justify-left  relative ',
      image: '',
      iconSvg: 'w-42 lg:w-50',
      text: 'end-plastic-cleanup-save-text md:inline lg:min-h-120 text-left pt-10 md:pt-20 md:pb-10 text-xs leading-seller18 lg:text-sm lg:leading-leading20  font-montserratSemiBold font-semibold',
      icon: 'end-plastic-cleanup-carousel-icon',
      iconColorVariant: 'w-10 inherit',
    },
    ImpactPullQuote: {
      default: {},
      wrapper: 'clearfix border-t border-hispanicborder border-b pointer-events-none hispanicstar-wrapper mb-30px mt-0 pt-30px pb-35 lg:mt-30px lg:pt-65 lg:mb-25px lg:pb-65 md:mt-0',
      image: 'hispanicstar-img w-full flex justify-center',
      text: 'pt-05 text-center m-auto uppercase hispanicstar-content font-montserratBold font-bold tracking-2 leading-wider text-18 text-success md:w-full md:px-10 xl:w-11/12 xl:tracking-2half lg:text-24 lg:leading-sitemap32 lg:pt-11',
      icon: 'hispanicstar-icon m-auto',
      iconColorVariant: 'w-10 inherit',
    },
    PampersCauseDetails: {
      default: {},
      wrapper: 'clearfix border-t-2 pointer-events-none pb-15 pt-25 md:pb-25 md:pt-35 xl:pb-25 xl:pt-50',
      image: 'PampersCause-img pb-25 xl:pb-45',
      iconImage: 'w-60 xl:w-80',
      text: 'PampersCause-content font-montserratlight font-light text-left text-16 lg:text-18 leading-leading28 lg:leading-leading35',
      textHeader: 'PampersCause-header font-montserratSemiBold font-semibold text-left text-14 lg:text-16 lg:leading-leading28',
      icon: 'hispanicstar-icon m-auto',
      iconColorVariant: 'w-10 inherit',
    },
    PampersCauseEducation: {
      default: {},
      wrapper: 'clearfix border-t-2 pointer-events-none pt-25 md:pt-35 xl:pb-25 xl:pt-60 pb-15',
      image: 'PampersCause-img pb-25 xl:pb-45',
      iconImage: 'w-75 xl:w-95',
      text: 'PampersCause-content font-montserratlight font-light text-left text-16 lg:text-18 leading-leading28 lg:leading-leading35',
      textHeader: 'PampersCause-header font-montserratSemiBold font-semibold text-left text-14 lg:text-16 lg:leading-leading28',
      icon: 'hispanicstar-icon m-auto',
      iconColorVariant: 'w-10 inherit',
    },
    PampersCauseDonation: {
      default: {},
      wrapper: 'clearfix border-t-2 border-b-2 pointer-events-none pb-15 pt-25 md:pt-35 xl:pb-25 xl:pt-50',
      image: 'PampersCause-img pb-25 xl:pb-45',
      iconImage: 'w-55 xl:w-65',
      text: 'PampersCause-content font-montserratlight font-light text-left text-16 lg:text-18 leading-leading28 lg:leading-leading35',
      textHeader: 'PampersCause-header font-montserratSemiBold font-semibold text-left text-14 lg:text-16 lg:leading-leading28',
      icon: 'hispanicstar-icon m-auto',
      iconColorVariant: 'w-10 inherit',
    },
  },
  [sustainability]: {
    default: {
      wrapper:
        'rewards-dashboard-tile relative flex flex-wrap flex-col justify-start pb-20 bg-white shadow-rewardsTile items-center w-full lg:w-330 md:w-280 md:mx-10px lg:mx-15px lg:my-11px my-15px md:my-15px block relative',
      dashboardWrapper: 'dashboard-wrapper cursor-not-allowed relative shadow-rewardsTile items-center lg:w-330 md:w-280 w-full md:mx-10px lg:mx-15px lg:my-10px justify-center my-2 block focus:outline-none ',
      image: 'rewards-dashboard-tile-image w-full px-0 min-h-130 lg:min-h-170 relative',
      imageFlag: 'absolute top-20 left-0 uppercase text-14 font-bold tracking-onehalf leading-seller18 py-08 pl-15 pr-12 text-white bg-warning',
      iconImage: 'w-full',
      dashboardImage: 'comming-soon-badge flex flex-wrap items-center justify-center shadow-rewardsBadge uppercase font-montserratBold font-bold text-14 text-white',
      comingRewardsSoontileBg: 'rewards-de-active',
      text: 'rewards-dashboard-tile-text bg-white text-left pl-25 pr-27 md:pr-31 pt-10 pb-25 xl:pl-35 xl:pr-37 xl:pt-15 xl:pb-32 justify-start text-14 font-montserratSemiBold font-semibold leading-leading20',
      textHeader: 'text-warning font-semiBold text-16 xl:text-18 leading-widest',
      textSecWrapper: 'font-semiBold pb-10 text-secWrapper text-16 xl:text-18 leading-light xl:leading-lightmedium',
      iconColorVariant: 'w-10 hidden',
    },
    olympicsDefault: {
      default: {},
      wrapper:
        'rewards-dashboard-tile text-black relative flex flex-wrap flex-col justify-start pb-20 bg-white shadow-rewardsTile items-center w-full lg:w-330 md:w-280 md:mx-10px lg:mx-15px lg:my-11px my-15px md:my-15px block relative',
      dashboardWrapper: 'dashboard-wrapper cursor-not-allowed relative shadow-rewardsTile items-center lg:w-330 md:w-280 w-full md:mx-10px lg:mx-15px lg:my-10px justify-center my-2 block focus:outline-none ',
      image: 'rewards-dashboard-tile-image w-full px-0 min-h-130 lg:min-h-170 relative',
      imageFlag: 'absolute top-20 left-0 uppercase text-14 font-bold tracking-onehalf leading-seller18 py-08 pl-15 pr-12 text-white bg-warning',
      iconImage: 'w-full',
      dashboardImage: 'comming-soon-badge flex flex-wrap items-center justify-center shadow-rewardsBadge uppercase font-montserratBold font-bold text-14 text-white',
      comingRewardsSoontileBg: 'rewards-de-active',
      text: 'rewards-dashboard-tile-text w-full text-14 font-montserratSemiBold font-semibold leading-leading20',
      textHeader: 'olympic-textHeader text-white bg-black text-center justify-center font-bold h-190 px-20 flex items-center font-semiBold text-27 leading-leading31',
      textSecWrapper: 'font-semiBold text-left pb-10 h-130 pl-25 pr-30 text-black pt-18 text-secWrapper text-black text-16 leading-light',
      iconColorVariant: 'w-10 hidden',
    },
    olympicsPrimary: {
      default: {},
      wrapper:
        'rewards-dashboard-tile text-black relative flex flex-wrap flex-col justify-start pb-20 bg-white shadow-rewardsTile items-center w-full lg:w-330 md:w-280 md:mx-10px lg:mx-15px lg:my-11px my-15px md:my-15px block relative',
      dashboardWrapper: 'dashboard-wrapper cursor-not-allowed relative shadow-rewardsTile items-center lg:w-330 md:w-280 w-full md:mx-10px lg:mx-15px lg:my-10px justify-center my-2 block focus:outline-none ',
      image: 'rewards-dashboard-tile-image w-full px-0 min-h-130 lg:min-h-170 relative',
      imageFlag: 'absolute top-20 left-0 uppercase text-14 font-bold tracking-onehalf leading-seller18 py-08 pl-15 pr-12 text-white bg-warning',
      iconImage: 'w-full',
      dashboardImage: 'comming-soon-badge flex flex-wrap items-center justify-center shadow-rewardsBadge uppercase font-montserratBold font-bold text-14 text-white',
      comingRewardsSoontileBg: 'rewards-de-active',
      text: 'rewards-dashboard-tile-text w-full text-14 font-montserratSemiBold font-semibold leading-leading20',
      textHeader: 'text-white bg-olympicsPrimary text-center justify-center font-bold h-180 px-20 flex items-center font-semiBold text-27 leading-leading31',
      textSecWrapper: 'font-semiBold text-left pb-10 h-130 pl-25 pr-30 text-black pt-18 text-secWrapper text-black text-16 leading-light',
      iconColorVariant: 'w-10 hidden',
    },
    olympicsWarning: {
      default: {},
      wrapper:
        'rewards-dashboard-tile text-black relative flex flex-wrap flex-col justify-start pb-20 bg-white shadow-rewardsTile items-center w-full lg:w-330 md:w-280 md:mx-10px lg:mx-15px lg:my-11px my-15px md:my-15px block relative',
      dashboardWrapper: 'dashboard-wrapper cursor-not-allowed relative shadow-rewardsTile items-center lg:w-330 md:w-280 w-full md:mx-10px lg:mx-15px lg:my-10px justify-center my-2 block focus:outline-none ',
      image: 'rewards-dashboard-tile-image w-full px-0 min-h-130 lg:min-h-170 relative',
      imageFlag: 'absolute top-20 left-0 uppercase text-14 font-bold tracking-onehalf leading-seller18 py-08 pl-15 pr-12 text-white bg-warning',
      iconImage: 'w-full',
      dashboardImage: 'comming-soon-badge flex flex-wrap items-center justify-center shadow-rewardsBadge uppercase font-montserratBold font-bold text-14 text-white',
      comingRewardsSoontileBg: 'rewards-de-active',
      text: 'rewards-dashboard-tile-text w-full text-14 font-montserratSemiBold font-semibold leading-leading20',
      textHeader: 'text-white bg-olympicsWarning text-center justify-center font-bold h-180 px-20 flex items-center font-semiBold text-27 leading-leading31',
      textSecWrapper: 'font-semiBold text-left pb-10 h-130 pl-25 pr-30 text-black pt-18 text-secWrapper text-black text-16 leading-light',
      iconColorVariant: 'w-10 hidden',
    },
  },

}
