const getColor = (variant) => {
  switch (variant) {
    case 'Red':
      return '#DD3447'
    case 'Blue':
      return '#267EA6'
    case 'Green':
      return '#57AB58'
    case 'DarkPink':
      return '#C7008E'
    case 'Teal':
      return '#2BAD9A'
    case 'DarkTeal':
      return '#1C798B'
    case 'BrightBlue':
      return '#0D53CA'
    case 'DarkGreen':
      return '#246D38'
    default:
      return null
  }
}
export default getColor
